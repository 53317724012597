<template>
    <div class="wrap d-flex justify-content-center">
       <div class="search">
          <input type="text" class="searchTerm" :placeholder="title" v-model="value" @keyup.enter="inputData">
       </div>
       <button type="submit" class="searchButton" @click= "inputData">
        <i class="fa fa-search"></i>
         </button>
      </div>
</template>

<script>
  export default {
    props: {
        title: String,
    },
    data() {
      return {
         value: '',
      };
    },
    methods: {
      inputData() {
         //console.log(this.value);
         this.$emit('handledata', this.value);
    }
  }
  }
</script>

<style scoped>

    .wrap{
            display: flex;
            margin-top: 100px;
            width: 100%;
            
        }
    .search {
      z-index:1;
      width: 470px;
      position: relative;
      display: flex;
      border: 2px solid #C0272D;
      border-right: none;
      border-radius: 40px 0 0 40px;
      background: #E5E5E5;
    }

    @media (max-width:700px){
        .search {
            width:50%;
        }

    }
   

    
    .searchTerm {
      width: 100%;
      border-right: none;
      padding: 15px;
      height: 20px;
      border-radius: 5px 0 0 5px;
      outline: none;
      color: #9DBFAF;
    }
    .searchTerm::placeholder{

    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    /* Black */
    color: #231F20;
    }
    .searchTerm:focus{
      color: #231F20;
      font-family: 'Quicksand';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    }

    
    .searchButton {
      position: relative;
      width: 103px;
      height: 64px;
      background:#231F20;
      align-content: center;
      color: #fff;
      cursor: pointer;
      font-size: 18px;
      border: 2px solid #C0272D;
      border-left: none;
      border-radius: 0px 40px 40px 0px;
    }

    .searchButton i {
      font-size: 40px;
      background: -webkit-linear-gradient(90deg, #CF9333 0.16%, #EAC348 15.12%, #FBF1CA 46.03%, #DBA939 99.88%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      height: 40px;
      width: 40px;
    }

    @media (max-width:760px){
      .search {
        left:5%;
      }
        .searchButton {
            width:30%;
            right:4%;
        }
        .searchButton i {
          position: absolute;
            font-size: 30px;
            justify-content: center;
            bottom: 12%;
        }

    }
    @media (max-width:700px){
        .searchButton {
            width:100px;
        }
        .searchButton i {
          position: absolute;
            font-size: 30px;
            justify-content: center;
            bottom: 10%;
        }
      }

      @media (max-width: 420px) {
        .wrap {
          width: 350px;
        }
        .searchButton i {
          position: absolute;
            font-size: 20px;
            justify-content: center;
            bottom: 0%;
            right:5%;
        }
        .search {
          left: 8%;
        }
        .searchButton {
            left: 0%;
        }
      }
      @media (max-width:380px){
        .searchTerm {
          width: 100%;
          padding: 1px;
        }
        .search {
          height: 100%;
        }
        .searchButton {
            width:82px;
            height:54px;
        }
        .searchButton i {
          position: absolute;
            font-size: 20px;
            justify-content: center;
            bottom: -10%;
            right:5%;
        }
      }

      @media (max-width:360px){
        .searchTerm {
          width: 100%;
          padding: 1px;
        }
        .search {
          height: 100%;
          width: 100px !important;
        }
        .searchButton {
            width:70px;
            height:54px;
        }
        .searchButton i {
          position: absolute;
            font-size: 16px;
            justify-content: center;
            bottom: -10%;
            right:-5%;
        }
      }
      @media (max-width:350px){
        .searchTerm {
          width: 100%;
          padding: 1px;
        }
        .search {
          height: 100%;
          width: 100px !important;
        }
        .searchButton {
            width:60px;
            height:54px;
        }
        .searchButton i {
          position: absolute;
            font-size: 10px;
            justify-content: center;
            bottom: -20%;
            right:-10%;
        }
        .wrap{
          width:fit-content;
        }
      }
      @media (max-width:340px){
        .searchTerm {
          width: 100%;
          padding: 1px;
        }
        .search {
          left: 8%;
          height: 100%;
          min-width: 230px !important;
        }
        .searchButton {
            width:60px;
            height:54px;
            left: 0%;
        }
        .searchButton i {
          position: absolute;
            font-size: 15px;
            justify-content: center;
            bottom: -16%;
            right:-7%;
        }
        
      }


   

   
</style>