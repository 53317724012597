<template>
    <!-- if no margin used, becomes resposive for all devices, have to fix the font size accordingly-->
  <div  class="container" style="min-height:100vh; margin-bottom:50px;">
      <div >
        <div class="d-flex justify-content-center">
            <div style="margin-bottom: 60px;">
                <Search title="Search Store or Location..." @handledata = "handleInput"></Search>
            </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div v-if="loading" class="row">
              <div class="col-lg-3 col-md-4 col-xs-12 response" v-for="n in 15" :key="n">
                
               
                <skeleton-box
                    v-if="loading"
                    width="270px"
                    height="253px"
                    style="border-radius:10%;"   
                  />

              </div>
            </div>
            <div v-else class="row">
              <div class="col-lg-3 col-md-4 col-xs-12 response" v-for="item in coordinates" :key="item.id">
                
               
                <WhereToBuyCard :Title = "item.name"   :Address = "item.address" :Img="item.shop.thumbnail" > </WhereToBuyCard>

              </div>
            </div>
            
          </div>
        </div>

      </div>
  </div>

</template>

<script>
  import WhereToBuyCard from './WhereToBuyCard.vue';
  import Search from './../common_components/Search.vue';
  import { mapGetters } from 'vuex'
  import SkeletonBox from './../common_components/SkeletonBox.vue'; 


  export default {
    
    components: { WhereToBuyCard, Search, SkeletonBox },
    data() {
      return {
        loading:true,
        address: [],
        API: 'AIzaSyCNYa3Uj9yuMVqop6avHI9T4ZiMJhye64I',
        valid: true,
        disabled: 'disabled',
        placeResultData: [],
        id: "",
        radius: "10",
        data: {},
        search: '',
        searchKey: '',
      };
    },
    computed: {
    ...mapGetters({
      coordinates: 'map/GET_SALESPOINTS'
    })
  },
  filters: {
    removeTag(item) {

      item = item.replace(/<\/?[^>]+(>|$)/g, "");
      return item;
    },
  },//end of filter
  methods: {

    handleInput(value) {
        this.search = value;
        this.searchSalesPoint();
    },

    
    searchSalesPoint(){
      this.$store.commit('map/SEARCH_SALESPOINT', this.search)
    },
    
  },//methods ends here

  async mounted() {
    await this.$store.dispatch('map/ACT_SALESPOINTS');
    this.loading = false;
    
    
  }
};
</script>

<style scoped>

 @media (max-width:920px){
        .response {
          display: flex;
          justify-content: center;
          margin: 0 auto;
        }
      
      }
</style>