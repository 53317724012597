<template>
    <div>
        <WholeWhereToBuy/>
    </div>
</template>

<script>
  import WholeWhereToBuy from '../../components/where-to-buy/WholeWhereToBuy.vue';

  export default {
    components: {WholeWhereToBuy}
  }
</script>