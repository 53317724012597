<template>
    <div class="card card-design"  >
      <a :href="'https://maps.google.com/?q='+Title.replace(/[{()}]/g,'')+Address" target="_blank">
      <div class="gradient-inside hover"> 
        <div  class="d-flex justify-content-center">
          <div style="margin-top: 10px;">
             <img v-if="hasImage" class="card-img-top content" :src="Img" alt="Card image cap" />
             <img v-else class="card-img-top content" src='/img/logo.png' alt="Card image cap"/>
         </div>
        </div>
        <div class="d-flex justify-content-center">
          <h5 class="card-title-own title-size text-center">{{ beforeBracket(this.Title) }}</h5>
        </div>
        <div class="d-flex justify-content-center">
          <h5 class="card-title-own title-size text-center mt-0" :title="insideBracket(this.Title)">({{ insideBracket(this.Title) }})</h5>
        </div>
        <div class="text-center">
          <h5 class="card-address-own mt-1">{{ Address }}</h5>
        </div>
        <div class="text-center d-flex justify-content-center card-follow-map" style="position: relative;">
          <div>
            <a :href="'https://maps.google.com/?q='+Title.replace(/[{()}]/g,'')+Address" target="_blank" >
              <img src="img/location-icon.svg" alt="l-icon" class="location-style"/>
            </a>
          </div>
          <div>
            <a :href="'https://maps.google.com/?q='+Title.replace(/[{()}]/g,'')+Address" target="_blank">Follow Map</a>
          </div>
        </div>
      </div>
      </a>
  
    </div>
</template>

<script>

  export default {
    props: {
      Address: String,
      Title: String,
      Img:String,

    },
    computed:{
      hasImage() {
      return !!this.Img;
    },

    },
    methods: {
      beforeBracket(random) {
        let stack = "";
        for(let i = 0; i < random.length; i++)
         {
           let x = random[i];
 
           if (x == '(' || x == '[' || x == '{')
           {
              break;
           }
           else {
             stack = stack + x;
           }
         }
         return stack;
         
      },
      insideBracket(random) {
        let val = random.replace( /(^.*\(|\).*$)/g, '' );
        return val;
      }
    }
   
    };

</script>

<style scoped>

@font-face {
    font-family: 'Quicksand';
    src: url('../../../public/fonts/Quicksand-VariableFont_wght.ttf');
} 
.content { 
  height:80px;
  width: 100%;
  
}
.gradient-inside {
  background-color: white;
  border-radius: 15%;
  margin-bottom: 50px;
  

}

.hover {
  cursor: pointer;
}

.title-size {
  max-width: 200px;
  display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
}

.card-design {
  width: 270px;
  height: 233px;
  border-radius: 15%;
  max-width: 324px; 
  max-height: 340px; 
  padding-top:5px; 
  padding-left: 1px;
  padding-right: 1px;
  margin: 12.5px;

  background: linear-gradient(90deg, #CF9333 0%, #EAC348 15%, #FBF1CA 46%, #A8752A 100%);
}

.card-title-own {
  height:fit-content;
  margin-top: 12.5px;
  font-family: Quicksand;
  font-weight: 700;
  font-style: Bold;
  font-size: 18px;
  line-height: 25px;
  line-height: 100%;
  letter-spacing: -2%;

}

.card-address-own {
    font-family: Quicksand;
    font-style: Bold;
    font-weight: 500;
    height: 30px;
    font-size: 13px;
    line-height: 16px;
    vertical-align: Top;
    letter-spacing: -2%

}

.card-follow-map {
    font-family: Quicksand;
    font-style: Bold;
    font-size: 13px;
    font-weight: 500;
    vertical-align: top;
    letter-spacing: -2%;

}

.card-follow-map:hover {
  font-size: 16px;
  font-weight: 700;
  color: tomato;
}

.location-style {
  max-height: 20px; 
  max-width: 20px;
  padding-bottom: 5px;
  margin-right: 5px;
}
@media only screen and (min-width: 921px) and (max-width: 1199px) {
	.card-design {
  width: 220px;
 
  }
}

@media (max-width:300px) {
  .card-design {
  width: 250px;
  height: 253px;
  }
}


</style>